import 'babel-polyfill';
import LazyLoad from 'vanilla-lazyload';
import './lib/classList';
import './lib/picturefill';
import siteHeaderHandler from './modules/siteHeaderHandler';
import scrollToTopHandler from './modules/scrollToTopHandler';
import searchWindowHandler from './modules/searchWindowHandler';
import searchFieldHandler from './modules/searchFieldHandler';
import sliderHandler from './modules/sliderHandler';
import singleArticleHandler from './modules/singleArticleHandler';
import subscribeFormHandler from './modules/subscribeFormHandler';
import imgLightboxHandler from './modules/imgLightboxHandler';
import numberBlockHandler from './modules/numberBlockHandler';
import emptyAdHandler from './modules/emptyAdHandler';


const vidsToDefer = document.querySelectorAll('iframe.article-content-block__video-holder__video');
const deferVideo = (video) => {
  if (!video || video.length < 1 || typeof video === 'undefined') {
    return false;
  }

  for (let i = 0; i < video.length; i++) {
   	if (video[i].getAttribute('data-src')) {
   		video[i].setAttribute('src', video[i].getAttribute('data-src'));
   	}
  }
};


document.addEventListener('DOMContentLoaded', () => {
  document.createElement('picture');
  // site header (navigation mm)
  	siteHeaderHandler();
 	// Handle scroll to top fn
 	scrollToTopHandler();

  // search window handler
  searchWindowHandler();

  // search field handler ( career, suppliers and buy-and-sell)
  searchFieldHandler();

  // sliders
  sliderHandler();

  // single article handler
  singleArticleHandler();

  // subscribe-form handler
  subscribeFormHandler();

  // imglightbox handler
  imgLightboxHandler();

  // numberBlockHandler
  numberBlockHandler();

  emptyAdHandler();
  // defer youtube script loading
  deferVideo(vidsToDefer);

  new LazyLoad();


  (function () {
    const _overlay = document.getElementById('searchWindow');
    let _clientY = null; // remember Y position on touch start

    _overlay.addEventListener('touchstart', (event) => {
      if (event.targetTouches.length === 1) {
        // detect single touch
        _clientY = event.targetTouches[0].clientY;
      }
    }, false);

    _overlay.addEventListener('touchmove', (event) => {
      if (event.targetTouches.length === 1) {
        // detect single touch
        disableRubberBand(event);
      }
    }, false);

    function disableRubberBand(event) {
      const clientY = event.targetTouches[0].clientY - _clientY;

      if (_overlay.scrollTop === 0 && clientY > 0) {
        // element is at the top of its scroll
        event.preventDefault();
      }

      if (isOverlayTotallyScrolled() && clientY < 0) {
        // element is at the top of its scroll
        event.preventDefault();
      }
    }

    function isOverlayTotallyScrolled() {
      // https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#Problems_and_solutions
      return _overlay.scrollHeight - _overlay.scrollTop <= _overlay.clientHeight;
    }
  }());
});
