import {Loader} from './loader'

const searchForm = document.getElementById('searchForm')
const searchInputField = document.getElementById('searchFieldInput')
const searchResultsWrap = document.getElementById('searchResults')
const searchResultsHolder = document.getElementById('searchResultsHolder')
const searchResultsHolderIsSearchingClass = 'is-searching'
let loader

let __delay = 0

// handle keypresses
const handleKeypress = (event, val) => {

	if(val.length && event.which !== 13) {
    clearTimeout(__delay);
    __delay = setTimeout(() => doAjaxSearch(val), 300)

  } else if (!val.length) {
    renderResponseHtml()
  } else {
    return
  }
}
//do search
const doAjaxSearch = (val) => {

	const request = new XMLHttpRequest();
	const formData = new FormData();
  formData.append('action', 'ajaxSearchEveryting');
  formData.append('queryString', val);

  request.onreadystatechange = () => {

      if(request.readyState === 1) {
		  // start loader here
		      searchResultsHolder.classList.add(searchResultsHolderIsSearchingClass)
		      loader.show()
      }

      // it's done
      if(request.readyState === 4) {
        // remove loader here
  			loader.hide()
  			setTimeout(() => {searchResultsHolder.classList.remove(searchResultsHolderIsSearchingClass)}, 800)
        // it's successful
        if(request.status === 200) {
          const res = JSON.parse(request.responseText);
            handleSearchResponse(res)
        } else {
          // handleError
          console.log('ERROR: ' +  request.status + ' ' + request.statusText);
        }
      }
    }

  request.open('POST', ajaxurl, true);
  request.send(formData);
}
//handle response
const handleSearchResponse = (res) => {
  renderResponseHtml(res.resHtml)
}
// render html of response
const renderResponseHtml = (res) => {
	if(!searchResultsHolder || typeof searchResultsHolder === 'undefined') {
		return false;
	}

	searchResultsHolder.innerHTML = ''

	if(typeof res === 'object') {
	  searchResultsHolder.classList.add(searchResultsHolderIsSearchingClass)
		searchResultsHolder.appendChild(res)
	  setTimeout(() => {searchResultsHolder.classList.remove(searchResultsHolderIsSearchingClass)}, 800)
  } else if(res === 'undefined' || typeof res === 'undefined'){
	  searchResultsHolder.innerHTML = ''
  } else {
	   searchResultsHolder.innerHTML = res
	}
}
// set everything up
const searchWindowHandler  = () => {

	if(!searchInputField || !searchForm) {
		return false
	}
  loader = new Loader(searchResultsWrap)
  if(searchForm.addEventListener) { searchForm.addEventListener('submit', (e) => e.preventDefault(), false) }
  else if(searchForm.attachEvent) { searchForm.attachEvent('onsubmit', (e) => e.preventDefault() ) }

	searchInputField.addEventListener('keyup', ((e) => handleKeypress(e, searchInputField.value)), false)
	searchInputField.addEventListener('touchend', ((e) => handleKeypress(e, searchInputField.value)), false)


}

export default searchWindowHandler
