export class Loader {

  constructor(parentElem) {
		if(!parentElem || typeof parentElem === 'undefined'){
			  return
		}
		this.parentElem = parentElem
		this.loaderElemId = 'searchLoader'
		this.loaderActiveClass = 'loader--active'
		this.loaderInActiveClass = 'loaderInActiveClassactive'
		this.loaderElem = null

		this.create()
  }

  create() {
	  if(this.loaderElem || typeof this.loaderElem === 'undefined') {
		  return
	  }
	  this.loaderElem = document.createElement('div')
	  this.loaderElem.id = `${this.parentElem.id}__${this.loaderElemId}`

	  var loader = document.createElement('div')
	  var innerCircle1 = document.createElement('span')
	  var innerCircle2 = document.createElement('span')

	  loader.appendChild(innerCircle1)
	  loader.appendChild(innerCircle2)
	  this.loaderElem.appendChild(loader)

	  loader.classList.add('loader')
	  innerCircle1.classList.add('loader-circle')
	  innerCircle2.classList.add('loader-circle')
	  this.loaderElem.classList.add('loader-wrap')
	  this.parentElem.appendChild(this.loaderElem)

  }

  show() {
	  if(this.loaderElem || typeof this.loaderElem !== 'undefined' ){
		  this.loaderElem.classList.add(this.loaderActiveClass)
	  }
  }

  // remove the loader if it exists
  hide() {
	  if(this.loaderElem || typeof this.loaderElem !== 'undefined' ) {
		  // setTimeout( () => {}, 100)
			  this.loaderElem.classList.remove(this.loaderActiveClass)
			  this.loaderElem.classList.add(this.loaderInActiveClass)
	  }
  }
}
