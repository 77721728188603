const siteHeaderElement = document.getElementById('siteHeader')
const siteHeaderMainContentRowElement = document.getElementById('siteHeaderMainContentRow')
const siteHeaderMainRow = document.getElementById('siteHeaderMainRow')

const menuTrigger  = document.getElementById('menuTrigger')
const menuTriggerIcon  = document.getElementById('menuTriggerIcon')
const siteHeaderNavigation = document.getElementById('siteHeaderNavigation')

const searchTriggers = document.querySelectorAll('.search-trigger')
const searchWindowElement = document.getElementById('searchWindow')

const newsletterSignupElement = document.getElementById('newsletterSignup')
const newsletterSignupTriggers = document.querySelectorAll('.newsletter-signup__trigger')

let menuOpenState = false;
let searchOpenState = false;
let newsletterSignupOpenState = false;

let siteHeaderLargeTopPosition
let siteHeaderSmallTopPosition

const siteHeaderOpenClass = 'site-header--open'
const bodyNavigationOpenClass = 'site-header__navigation--open'
const bodySearchWindowOpenClass = 'site-header__search-window--open'
const searchWindowOpenClass = 'search-window--open'
const siteHeaderNavigationOpenClass = 'site-header__navigation-section--open'
const menuTriggerOpenClass = 'is-active'
const siteHeaderScrolledBodyClass = 'header--scrolled'
const siteHeaderScrolledClass = 'site-header--scrolled'
const newsletterSignupOpenClass = 'newsletter-signup--open'
const bodyNewsletterSignupOpenClass = 'site-header__newsletter-signup--open'

// Nav menu functions
const NavigationMenu = {
	open : (event) => {
    menuOpenState = true
    siteHeaderElement.classList.add(siteHeaderOpenClass)
    document.body.classList.add(bodyNavigationOpenClass)
    siteHeaderNavigation.classList.add(siteHeaderNavigationOpenClass)
    menuTriggerIcon.classList.add(menuTriggerOpenClass)
 	},
	close : (event) => {
    menuOpenState = false
    siteHeaderElement.classList.remove(siteHeaderOpenClass)
    document.body.classList.remove(bodyNavigationOpenClass)
    siteHeaderNavigation.classList.remove(siteHeaderNavigationOpenClass)
    menuTriggerIcon.classList.remove(menuTriggerOpenClass)
	},
  toggle : (event) => {
    event.preventDefault
    SearchWindow.close()
    NewsletterSignup.close()

    if(!menuOpenState) {
      NavigationMenu.open()
    } else {
      NavigationMenu.close()
    }
  },
  init : () => {

    if(!menuTrigger || !menuTriggerIcon || !siteHeaderNavigation) {
      console.log('something is missing yo')
      return false
    }

    menuTrigger.addEventListener('click', ((e) => NavigationMenu.toggle(e)), false)

  }
}

//Search window functions
const SearchWindow = {

	open : (event) => {
    searchOpenState = true
    siteHeaderElement.classList.add(searchWindowOpenClass)
    document.body.classList.add(bodySearchWindowOpenClass)
    searchWindowElement.classList.add(searchWindowOpenClass)
    searchWindowElement.querySelector('.search-window__content__search-form__input').value = ''
    searchWindowElement.querySelector('.search-window__content__search-form__input').focus()
 	},

	close : (event) => {
    searchOpenState = false
    siteHeaderElement.classList.remove(searchWindowOpenClass)
    document.body.classList.remove(bodySearchWindowOpenClass)
    searchWindowElement.classList.remove(searchWindowOpenClass)
	},

  toggle : (event) => {
    event.preventDefault

    NavigationMenu.close()
    NewsletterSignup.close()


    if(!searchOpenState) {
      SearchWindow.open()
    } else {
      SearchWindow.close()
    }
  },

  init : () => {
    if(!searchTriggers || !searchWindowElement) {
      return false
    }
    for(let _trigger of searchTriggers) {
       _trigger.addEventListener('click', ((e) => SearchWindow.toggle(e)), false)
    }
  }
}

//Newsletter  functions
const NewsletterSignup = {
  open: (event) => {
    newsletterSignupOpenState = true
    siteHeaderElement.classList.add(newsletterSignupOpenClass)
    siteHeaderElement.classList.add(siteHeaderOpenClass)
    document.body.classList.add(bodyNewsletterSignupOpenClass)
    newsletterSignupElement.classList.add(newsletterSignupOpenClass)
  },
  close : (event) => {
    newsletterSignupOpenState = false
	  if(window.pageXOffset > 799) {
		    siteHeaderElement.classList.remove(siteHeaderOpenClass)
    }
    siteHeaderElement.classList.remove(newsletterSignupOpenClass)
    document.body.classList.remove(bodyNewsletterSignupOpenClass)
    newsletterSignupElement.classList.remove(newsletterSignupOpenClass)
  },
  toggle : (event) => {
    event.preventDefault

    if(window.pageXOffset > 799) {
      NavigationMenu.close()
    }
    SearchWindow.close()

    if(!newsletterSignupOpenState) {
      NewsletterSignup.open()
    } else {
      NewsletterSignup.close()
    }
  },
  init : () => {
    if(!newsletterSignupTriggers || !newsletterSignupElement){
      return false
    }

    const signupFormElement = document.getElementById('mc-embedded-subscribe-form')

	if(signupFormElement.addEventListener) {
		signupFormElement.addEventListener('submit', (e) => {
      NewsletterSignup.close()
      setTimeout(() => {
        signupFormElement.reset()
      }, 500)
		}, false)
	} else if(signupFormElement.attachEvent) {
		signupFormElement.attachEvent('onsubmit', (e) => {
		NewsletterSignup.close()

		})
	}

    for(let _trigger of newsletterSignupTriggers) {
         _trigger.addEventListener('click', ((e) => NewsletterSignup.toggle(e)), false)
    }
  }
}
const siteHeaderScrollHandler = (headerFirstRowBottom, headerFirstRowTop) => {
  // if screen is larger than 799, use the nav menu as top, otherwise use the headers top. the padding is compensation for loss in height when the header gets a fixed position


  if(window.innerWidth > 799) {
    if(window.pageYOffset > headerFirstRowBottom) {
      NewsletterSignup.close()
      document.body.classList.add(siteHeaderScrolledBodyClass)
      document.body.style.paddingTop = document.getElementById('siteHeaderNavigation').getBoundingClientRect().height + 'px'
      siteHeaderElement.classList.add(siteHeaderScrolledClass)
    } else {
      document.body.classList.remove(siteHeaderScrolledBodyClass)
      document.body.style.paddingTop = 0
      siteHeaderElement.classList.remove(siteHeaderScrolledClass)
    }
  } else {
    if(window.pageYOffset > headerFirstRowTop) {
      NewsletterSignup.close()
      document.body.classList.add(siteHeaderScrolledBodyClass)
	    document.body.style.paddingTop = siteHeaderElement.getBoundingClientRect().height + 'px'
      siteHeaderElement.classList.add(siteHeaderScrolledClass)
    } else {
      document.body.classList.remove(siteHeaderScrolledBodyClass)
	  document.body.style.paddingTop = 0
      siteHeaderElement.classList.remove(siteHeaderScrolledClass)
    }
  }
}

function getCoords(elem, _modifier) { // crossbrowser version
  let modifier = _modifier
  if(!_modifier) {
    modifier = 'top'
  }
    var box = elem.getBoundingClientRect();

    var body = document.body;
    var docEl = document.documentElement;

    var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;

    var clientTop = docEl.clientTop || body.clientTop || 0;

    var value  = (modifier === 'bottom' ? box.bottom : box.top) +  scrollTop - clientTop;
    return  Math.round(value);
}
const siteHeaderHandler  = () => {

  // init the stuffs
   NavigationMenu.init()
   SearchWindow.init()
   NewsletterSignup.init()
   siteHeaderLargeTopPosition = siteHeaderMainRow.getBoundingClientRect().bottom
   siteHeaderSmallTopPosition = siteHeaderMainRow.getBoundingClientRect().top
  // update theese values on window resiz & close open menus etc if user minimizes the window too much
	window.addEventListener('resize', (e) => {

		if(window.innerWidth < 800 ) {

			NavigationMenu.close()
		  //SearchWindow.close()
		  NewsletterSignup.close()
		}

		// TODO: This gets neg value if resizd when scrolled
		siteHeaderLargeTopPosition = getCoords(siteHeaderMainRow, 'bottom')
		siteHeaderSmallTopPosition = getCoords(siteHeaderMainRow, 'top')

	}, false)

  window.addEventListener('scroll', (e) => siteHeaderScrollHandler(siteHeaderLargeTopPosition, siteHeaderSmallTopPosition), false)

}

export default siteHeaderHandler
