import Flickity from 'flickity'
import LazyLoad from 'vanilla-lazyload';

const smallArticleSliders = () => {
	const sliders = document.querySelectorAll('.small-article-slider__wrapper');

	if(!sliders) {
		return false
	}

	for(let __slider of sliders) {
		__slider.classList.remove('is-hidden');
		// trigger redraw for transition
		__slider.offsetHeight;
		__slider.offsetWidth;

		new Flickity( __slider, {
			// options
			cellAlign: 'left',
			contain: true,
			freeScroll: true,
			prevNextButtons: false,
			pageDots: false,
			watchCSS: true,
		});
	}
}


const featuredImagesSliderHandler = () => {
		const sliders = document.querySelectorAll('.article-header__featured-images--slideshow')
		if(!sliders || typeof sliders === 'undefined') {
			return false
		}

		for(let __slider of sliders) {
			__slider.classList.remove('is-hidden');
			// trigger redraw for transition
			__slider.offsetHeight;
			__slider.offsetWidth;

			new Flickity( __slider, {
				// options
				cellSelector: '.article-header__featured-images__image-wrap',
				cellAlign: 'left',
				contain: true,
				adaptiveHeight: true,
				prevNextButtons: true,
				imagesLoaded: true,
				pageDots: false,
				arrowShape: 'M83.3,45.8H32.6l23.3-23.3L50,16.7L16.7,50L50,83.3l5.9-5.9L32.6,54.2h50.7V45.8z'
			});
			new LazyLoad();
		}
	}



const sliderHandler = () => {
	smallArticleSliders()
	featuredImagesSliderHandler()
}

export default sliderHandler
