import Flickity from 'flickity-imagesloaded'

class imgLightbox {
  constructor(articles)
  {
    if(!articles || typeof articles === 'undefined'){
        return
    }
    this.articles = articles
    this.images = []

    this.openStatus = null
    this.closeButton = null
    this.captionHolder = null
    this.captionHolderTextHolder = null
    this.sliderWrapper = null
    this.lightbox = null
    this.imageSlider = null
    this.lightboxWrapperClass = 'image-lightbox'
    this.lightboxWrapperOpenClass = 'image-lightbox--open'
    this.lightboxBodyOpenClass = 'image-lightbox--is-open'
    this.imageClass = 'image-lightbox__slider-wrap__image'

    this.create()
  }

  createNewImage(imageSrc)
  {
    const newImage = new Image()
    newImage.classList.add(this.lightboxWrapperClass + '__slider-wrap__image')
    newImage.src = imageSrc.getAttribute('data-fullwidth-img-src')
    newImage.alt = imageSrc.alt
    return newImage
  }


	getCurrentImagesImageContent(imageWrapper)
  {
    return  Array.from(imageWrapper.querySelectorAll('img.article-header__featured-images__image'))
	}


  create()
  {
    // get all images
    //this.articles.forEach( article => this.images.push(article.querySelector('img.article-header__featured-images__image')))

    // lightbox container
    this.lightbox = document.createElement('div')
    this.lightbox.id = 'imageLightBox'
    this.lightbox.classList.add(this.lightboxWrapperClass)

    // image / image-slider container
    const contentWrap = document.createElement('div')
	contentWrap.id = 'contentWrap'
    this.sliderWrapper = document.createElement('div')
    this.sliderWrapper.classList.add(this.lightboxWrapperClass + '__slider-wrap')
    this.sliderWrapper.id = 'sliderWrapper'

    // create caption holder
    this.captionHolder = document.createElement('div')
    this.captionHolderTextHolder = document.createElement('p')
    this.captionHolder.appendChild(this.captionHolderTextHolder)

    this.captionHolder.id = 'imageCaptionHolder'
    this.captionHolder.classList.add(this.lightboxWrapperClass + '__slider-wrap__caption-holder')

    // create close btn
    this.closeButton = document.createElement('div')
    this.closeButton.classList.add(this.lightboxWrapperClass + '__slider-wrap__close-button')
    this.closeButton.id = 'imgLightBoxCloseButton'

    // add the slider to the lightbox, and add the lightbox to the body
    this.lightbox.appendChild(this.sliderWrapper)
    document.body.appendChild(this.lightbox)

    // append the caption holder
    this.sliderWrapper.appendChild(this.captionHolder)

    // append the cloe-button and add event listener
    this.sliderWrapper.appendChild(this.closeButton)
	  this.closeButton.addEventListener('click', () => this.close(), false)
  }

  open(imageWrapper, selectedImageIndex)
  {
    //
    this.openStatus = true
    // if we have a slider, set the current index of the slider to the index of the clicked image

    this.images = this.getCurrentImagesImageContent(imageWrapper)
    this.images.forEach(imageSrc => this.sliderWrapper.appendChild(this.createNewImage(imageSrc)))

    // make a slider of it of we have more than one image
    if(this.images.length > 1) {
        this.imageSlider = new Flickity( this.sliderWrapper, {
			cellSelector: '.' + this.imageClass,
        	imagesLoaded: true,
        	wrapAround: true,
        	prevNextButtons: true,
        	pageDots: false,
        	arrowShape: 'M36.9,87.4 43.6,80.6 18.3,55.3 100,55.3 100,45.7 18.3,45.7 43.6,20.4 36.9,13.6 0,50.5 z'
      	})
        this.imageSlider.on( 'select', () =>  {
            this.captionHolder.offsetWidth
            this.captionHolder.style.display = !this.imageSlider.selectedElement.alt ? 'none' : 'block'
            this.captionHolderTextHolder.textContent = this.imageSlider.selectedElement.alt

        })
    } else {
      this.captionHolderTextHolder.textContent = this.images.map( image => image.alt)
    }

    this.imageSlider ? this.imageSlider.select( selectedImageIndex, false, true ) : false
    // add class to "open" lightbox
    this.lightbox.classList.add(this.lightboxWrapperOpenClass)
    document.body.classList.add(this.lightboxBodyOpenClass)

  }

  clearAllImages()
  {
    console.log(this.sliderWrapper)
    Array.from(this.sliderWrapper.querySelectorAll('.'+this.imageClass)).forEach( image => image.remove())
  }

  close()
  {
    //
    this.openStatus = false
    // remove class to "close" lightbox

    this.lightbox.classList.remove(this.lightboxWrapperOpenClass)
    document.body.classList.remove(this.lightboxBodyOpenClass)
    this.clearAllImages()
    setTimeout(() => {
      if(this.imageSlider) {
        this.imageSlider.destroy()
        this.imageSlider = null
        this.images = null
      }
    }, 800)

  }
}


const imgLightboxHandler = () => {
  const articles = Array.from(document.querySelectorAll('.article'))

  if(articles.length <= 0) {
    return false
  }

  const lightBox = new imgLightbox(articles)

  articles.forEach( (article) => {
    const imgTrigger = article.querySelector('.article-header__featured-images__zoom-trigger')

    if(!imgTrigger || typeof imgTrigger === 'undefined') {
      return false
    }

    imgTrigger.addEventListener('click', () => lightBox.open(imgTrigger.parentNode, articles.indexOf(article)), false)
  })
}

export default imgLightboxHandler
