import Velocity from  'velocity-animate';

const scrollToTop = (e) => {
  e.preventDefault
  Velocity(document.body, 'scroll', {duration: 500});
}

const scrollToTopHandler  = () => {
  const trigger  = document.getElementById('backToTop')
  if(!trigger) {
	  return false
  }
  trigger.addEventListener('click', ((e)=>scrollToTop(e)), false)
}

export default scrollToTopHandler
