// import $ from "jquery";
// import './../lib/chocolat.js'
import Flickity from 'flickity-imagesloaded';
import Velocity from 'velocity-animate';
import LazyLoad from 'vanilla-lazyload';
import emptyAdHandler from './emptyAdHandler';

const body = document.body;
const html = document.documentElement;
let isScrolling;

const singleArticleView = document.getElementById('singleArticleView');
const singleArticleMainColumn = document.getElementById('singleArticleMainColumn');
const singleArticleSidebar = document.getElementById('singleArticleSidebar');
let hasFetchedMoreArticles = false;

const breakpoints = {
  contentTopPosition: singleArticleView ? singleArticleView.offsetTop : false,
  contentBottomPosition: singleArticleView ? singleArticleView.getBoundingClientRect().bottom : false,
  sidebarTopPosition: singleArticleSidebar ? singleArticleSidebar.offsetTop : false,
  documentHeight: null,
  viewPortMiddlePoint: (window.innerHeight / 1.8),
};

const handleScrollingOnPage = () => {
  // Listen for scroll events
  const __singleArticleView = document.getElementById('singleArticleView');
  let scrollTimer = null;

  if (__singleArticleView) {
    window.addEventListener('scroll', ((e) => {
      if (!scrollTimer) {
        const bottom = window.innerHeight + window.pageYOffset;
        const rect = __singleArticleView.getBoundingClientRect();
        const bottomRect = rect.bottom - 500; // 200 is the offset

        if (bottomRect < (window.innerHeight - 200)) {
          fetchRestOfArticles();
        }
        scrollTimer = true;
        setTimeout(() => {
          scrollTimer = false;
        }, 200);
      }
    }), false);
  }
};
const disqusButtonActiveClass = 'article-content__disqus-wrap__comments-trigger--active';

// const articleSidebarScrollHandler = () => {
//   if (window.innerWidth < 800 || !singleArticleSidebar) {
//     return false;
//   }
//   if (window.innerWidth < 799) {
//     singleArticleSidebar.style.paddingTop = 0;
//     singleArticleSidebar.style.transform = 'translateY(0)';
//     singleArticleSidebar.classList.remove('is-scrolled');
//     return;
//   }
//   window.addEventListener('scroll', ((e) => {
//     const __sidebarTopOffset = document.getElementById('singleArticleMainColumn').getBoundingClientRect().top;
//     const sidebarTopOffset = Math.abs(document.getElementById('singleArticleMainColumn').getBoundingClientRect().top);
//     const siteHeaderOffset = document.getElementById('siteHeaderNavigation').getBoundingClientRect().height;
//     const contentBottomPos = document.getElementById('singleArticleMainColumn').getBoundingClientRect().height;
//     const sidebarBoundingRect = singleArticleSidebar.getBoundingClientRect();
//     const stickySidebar = ((window.pageYOffset + siteHeaderOffset) > breakpoints.contentTopPosition && window.pageYOffset < breakpoints.documentHeight);


//     const sidebarOffset = Math.round(sidebarTopOffset + (siteHeaderOffset + 24)); // =)
// 			    // Clear our timeout throughout the scroll
// 			    window.clearTimeout(isScrolling);

// 			    // Set a timeout to run after scrolling ends
// 			    isScrolling = setTimeout(() => {
//       if (stickySidebar && __sidebarTopOffset < 0) {
//         singleArticleSidebar.classList.add('is-scrolled');
//         singleArticleSidebar.style.transform = `translateY(${sidebarOffset}px)`;
//       }	else {
//         singleArticleSidebar.style.transform = 'translateY(0)';
//         singleArticleSidebar.classList.remove('is-scrolled');
//       }
// 			    }, 20);
//   }), false);
// };
const activeSidebarItemHandler = (__articleId) => {
  if (window.innerWidth < 800 || !singleArticleView) {
    return false;
  }

  const menuItems = document.querySelectorAll('.single-article-sidebar__article-navigation__content__links__link');
  if (menuItems.length === 0) {
    return false;
  }

  const activeClass = 'single-article-sidebar__article-navigation__content__links__link--active';
  for (const __menuItem of menuItems) {
    const itemId = __menuItem.getAttribute('data-articleid');

    if (itemId === __articleId) {
      __menuItem.classList.add(activeClass);
    } else {
      __menuItem.classList.remove(activeClass);
    }
  }
};

const articleSidebarNavigationHandler = () => {
  if (window.innerWidth < 800 || !singleArticleSidebar) {
    return false;
  }
  const menuItems = document.querySelectorAll('.single-article-sidebar__article-navigation__content__links__link');
  if (menuItems.length === 0) {
    return false;
  }
  for (const __menuItem of menuItems) {
    __menuItem.addEventListener('click', (e) => {
      const itemId = __menuItem.getAttribute('data-articleid');
      const correspondingArticle = document.getElementById(`article__${itemId}`);
      if (correspondingArticle) {
        Velocity(correspondingArticle, 'scroll', { duration: 500, offset: -100 });
        e.preventDefault();
      }
    }, false);
  }
};

const activeArticleHandler = () => {
  const articles = singleArticleView.querySelectorAll('.article');

  if (!articles || typeof articles !== 'object') {
    return false;
  }

  if (articles.length > 1) {
	 window.addEventListener('scroll', ((e) => {
      for (const __article of articles) {
		  if (breakpoints.viewPortMiddlePoint >= __article.getBoundingClientRect().top && breakpoints.viewPortMiddlePoint <= __article.getBoundingClientRect().bottom) {
          const articleSlug = __article.getAttribute('data-articleslug');
          activeSidebarItemHandler(__article.getAttribute('data-articleid'));
          // history.pushState(null, null, `${articleSlug}`);
          __article.classList.add('single-article-type--active');
		  } else {
			  __article.classList.remove('single-article-type--active');
		  }
      }
    }), false);
  } else if (articles.length === 1) {
    const __article = articles[0];
    __article.classList.add('single-article-type--active');
  }
};


const loadDisqusComments = (element, postTitle, postUrlTag) => {
  const identifier = postTitle;

  // Including the hashbang ('/#!') is important.
  const url = `${window.location.origin}/#!${postUrlTag}`;

  const disqus_identifier = identifier;
  const disqus_url = url;
  const allCommentHolders = document.querySelectorAll('.article-content__disqus-wrap');

  if (window.DISQUS) {
	  for (const holder of allCommentHolders) {
      const commentHolder = holder.querySelector('.comments-holder');
      const triggerButton = holder.querySelector('.article-content__disqus-wrap__comments-trigger');
      triggerButton.classList.remove(disqusButtonActiveClass);
		  commentHolder.innerHTML = '';
	  }
    const dsqElem = document.createElement('div');
    dsqElem.id = 'disqus_thread';
    dsqElem.classList.add('disqus-thread');

    element.appendChild(dsqElem);

    /** if Disqus exists, call it's reset method with new parameters * */
    DISQUS.reset({
      reload: true,
      config() {
        // important to convert it to string
        this.page.identifier = identifier.toString();
        this.page.url = url;
      },
    });
  }
};

const disqusHandler = () => {
  const triggers = document.querySelectorAll('button.article-content__disqus-wrap__comments-trigger');

  if (!triggers || typeof triggers === 'undefined' || triggers.length < 1) {
    return false;
  }

  // Disqus stuffs
  const disqus_shortname = 'besokslivse';
  let disqus_identifier;
  let disqus_url;

  // Loads the Disqus JS file that will create the comment form and threads.
  const dsq = document.createElement('script'); dsq.type = 'text/javascript'; dsq.async = true;
  dsq.src = `https://${disqus_shortname}.disqus.com/embed.js`; // Found in disqus.js script
  document.body.appendChild(dsq);

  document.body.addEventListener('click', (e) => {
    const buttons = document.querySelectorAll('button.article-content__disqus-wrap__comments-trigger');

    for (const button of buttons) {
      if (button == e.target) {
        const trigger = e.target;
        const commentsHolderId = trigger.getAttribute('data-id');
        const commentsHolderTitle = trigger.getAttribute('data-title');
        const commentsHolderSlug = trigger.getAttribute('data-slug');
        const commentsHolder = document.getElementById(commentsHolderId);
        loadDisqusComments(commentsHolder, commentsHolderTitle, commentsHolderSlug);
        trigger.classList.add(disqusButtonActiveClass);
      }
    }
  }, false);
};
const fetchRestOfArticles = () => {
  const container = document.getElementById('singleArticleMainColumn');
	const moreArticlesContainer = document.getElementById('moreArticles');

  if (container && moreArticlesContainer && !hasFetchedMoreArticles) {
    const postId = container.getAttribute('data-mainid');

    if (postId) {
      const request = new XMLHttpRequest();
      const formData = new FormData();
      formData.append('action', 'fetchMorePosts');
      formData.append('postId', postId);

      request.onreadystatechange = () => {
        // it's done
        if (request.readyState === 4) {
          if (request.status === 200) {
			  moreArticlesContainer.innerHTML += request.responseText;
            updateBreakpoints();
            activeArticleHandler();
            new LazyLoad();
            // remove empty ads

            // ugly, but to load tweet & fb-share buttons again
            twttr.widgets.load();
            FB.XFBML.parse();
          }
        }
      };

      request.open('POST', ajaxurl, true);
      request.send(formData);
      hasFetchedMoreArticles = true;
    }
  }
};

const updateBreakpoints = () => {
  const __singleArticleView = document.getElementById('singleArticleView');
  const __singleArticleSidebar = document.getElementById('singleArticleSidebar');

  if (!__singleArticleView || !__singleArticleSidebar) {
 		return false;
  }

  breakpoints.contentTopPosition = __singleArticleView.offsetTop;
  breakpoints.contentBottomPosition = __singleArticleView.getBoundingClientRect().bottom;
  breakpoints.sidebarTopPosition = __singleArticleSidebar.offsetTop;
  breakpoints.documentHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
  breakpoints.viewPortMiddlePoint = (window.innerHeight / 1.8);
};

const singleArticleHandler = () => {
  if (!singleArticleView || !singleArticleMainColumn) {
    return false;
  }

  // window.addEventListener('resize', () => updateBreakpoints(), false)

  updateBreakpoints();
  // articleSidebarScrollHandler();
  activeArticleHandler();
  articleSidebarNavigationHandler();
  disqusHandler();
  handleScrollingOnPage();
  // fetchRestOfArticles()
};


export default singleArticleHandler;
