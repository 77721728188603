import CountUp from 'countup.js'
const numberBlockElement = document.getElementById('todaysNumberSection');
const numberBlockTarget = numberBlockElement ? numberBlockElement.querySelector('#todaysNumberTarget') : false;
let hasScrolledIntoView = false
let __threshhold = null
let __countUp

const isInViewport = (element) => {
  var rect = element.getBoundingClientRect();
  var html = document.documentElement;
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || html.clientHeight) &&
    rect.right <= (window.innerWidth || html.clientWidth)
  );
}



const handleScroll = (targetContainer, number) => {
	if(isInViewport(number) && !hasScrolledIntoView) {
		hasScrolledIntoView = !hasScrolledIntoView
			__countUp.start()
	}
}



const numberBlockHandler = () => {
	if((!numberBlockElement || typeof numberBlockElement === 'undefined' ) || !numberBlockTarget || typeof numberBlockTarget === 'undefined' ) {
		return false;
	}
	const easingFn = function (t, b, c, d) {
		  var ts = (t /= d) * t;
		  var tc = ts * t;
		  return b + c * (tc + -3 * ts + 3 * t);
		}
	const options = {
  useEasing : easingFn,
  useGrouping : true,
  separator : ',',
  decimal : false
	}
	__countUp = new CountUp(numberBlockTarget, 0, parseInt(numberBlockTarget.innerHTML), 0, 4, options);
	window.addEventListener('scroll', (e) => handleScroll(numberBlockElement, numberBlockTarget), false)
}
export default numberBlockHandler
