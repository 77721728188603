const emptyAdHandler = () => {
  const adElements = Array.from(document.querySelectorAll('.ad-section'));
  if (!adElements || typeof adElements === 'undefined') return false;
  return setTimeout(() => adElements.map((adElem) => {
    const googleAdElem = adElem.querySelector('.ad-section__ad-item');
    if (!googleAdElem || typeof googleAdElem === 'undefined') return false;
    return googleAdElem.clientHeight < 1 ? adElem.remove() : false;
  }), 2000);
};

export default emptyAdHandler;
