const subscriptionForm = document.getElementById('subscriptionForm')
const otherRecipentAdressCheckbox = document.getElementById('otherRecipentAdressCheckbox')
const subscriptionFormSubmitButton = document.getElementById('subscriptionFormSubmitButton')
const otherRecipentAdressFormWrap = document.getElementById('otherRecipientAdress')
const otherRecipientAdressInactiveClass = 'other-recipient-adress--inactive'
const subscriptionFormMessageId = 'subscriptionFormMessage'
let otherRecipientState = false

const otherRecipentHandler = (event) => {
	event.preventDefault

	otherRecipientState = !otherRecipientState

	// set the inputs to required
	const otherRecipentAdressInputs = otherRecipentAdressFormWrap.querySelectorAll('input')
	if(otherRecipentAdressInputs.length > 0 && typeof otherRecipentAdressInputs !== 'undefined'){
		for (let __input of otherRecipentAdressInputs) {
			// __input.setAttribute('required', otherRecipientState)
			__input.required = otherRecipientState
		}
	}

	// Show the fields
	if(otherRecipientState) {
		otherRecipentAdressFormWrap.classList.remove(otherRecipientAdressInactiveClass)
	} else {
		otherRecipentAdressFormWrap.classList.add(otherRecipientAdressInactiveClass)
	}

}


// remvove prev message if exists
const removeRequestDoneMessage = () => {
	const message = document.getElementById(subscriptionFormMessageId)
	if(!message || typeof message === 'undefined'){
		return false
	}
	message.remove()

}
const appendRequestDoneMessage = (msg) => {

	const messageHolder = document.createElement('div');
	const messageContent = document.createTextNode(msg);
	messageHolder.appendChild(messageContent); //add the text node to the newly created div.
	messageHolder.id = subscriptionFormMessageId
	messageHolder.classList.add('form__message','form__message--hidden',  'subscription-form__message','subscription-form__message--hidden')
	subscriptionForm.appendChild(messageHolder)

	setTimeout(() => {
		messageHolder.classList.remove('form__message--hidden', 'subscription-form__message--hidden')
	}, 400)

}

const handleFormSubmission = (event) => {
	event.preventDefault()

	const request = new XMLHttpRequest();
	const formData = new FormData(subscriptionForm);
	formData.append('action', 'ajaxSubmitSubscriptionForm');
	formData.append('_ajax_nonce', subscriptionForm.getAttribute('data-nonce'));

	  request.onreadystatechange = () => {
	    if(request.readyState === 1) {
			    // start loader here
				removeRequestDoneMessage()
				subscriptionFormSubmitButton.disabled = true
			}

	    // it's done
	    if(request.readyState === 4) {
			subscriptionFormSubmitButton.disabled = false
			console.log(request)
			console.log('*******')
			const res = JSON.parse(request.responseText);
			appendRequestDoneMessage(res.message)
	        // it's successful
	        if(request.status === 200) {
						setTimeout(() => subscriptionForm.reset(), 800)
	        } else {
	          // handleError
	          console.log('ERROR: ' +  request.status + ' ' + request.statusText);
	        }
	      }
	  }

	  request.open('POST', ajaxurl, true);
	  request.send(formData);
}

const subscribeFormHandler  = () => {

  if((!otherRecipentAdressCheckbox || typeof otherRecipentAdressCheckbox === 'undefined')
	|| (!otherRecipentAdressFormWrap || typeof otherRecipentAdressFormWrap === 'undefined'))
	{
	  return false
  }

  if(subscriptionForm.addEventListener) { subscriptionForm.addEventListener('submit', (event) => handleFormSubmission(event), false) }
  else if(subscriptionForm.attachEvent) { subscriptionForm.attachEvent('onsubmit', (event) => handleFormSubmission(event) ) }

	otherRecipentAdressCheckbox.addEventListener('change', ((event) => otherRecipentHandler(event)), false)
}

export default subscribeFormHandler
