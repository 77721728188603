import {Loader} from './loader'
const searchForm = document.getElementById('searchSpecialPostsForm')
const searchInputField = document.getElementById('searchSpecialPostsFormFieldInput')
const searchHolder = document.getElementById('searchHolder')
const searchResultsHolderIsSearchingClass = 'is-searching'
let loader
let postType
let allOriginalPosts
let searchResultsHolder
let __delay = 0

// handle keypresses
const handleKeypress = (event, val) => {
	if(val.length && event.which !== 13) {
    //small delay to keep making request while user is still typing
		clearTimeout(__delay);
	  __delay = setTimeout(() => doAjaxSearch(val), 400)
  } else if (!val.length) {
    renderResponseHtml(allOriginalPosts)
  } else {
    return false
  }
}

//do search
const doAjaxSearch = (val) => {

	if(!postType || typeof postType === 'undefined') {
		return false;
	}

	const request = new XMLHttpRequest();
	const formData = new FormData();
	formData.append('action', 'ajaxSearchSpecialPosts');
	formData.append('queryString', val);
	formData.append('posttype', postType);

  request.onreadystatechange = () => {
    if(request.readyState === 1) {
		    // start loader here
            searchResultsHolder.classList.add(searchResultsHolderIsSearchingClass)
			loader.show()
      }

    // it's done
    if(request.readyState === 4) {
        // remove loader here
		      loader.hide()
          setTimeout(() => {searchResultsHolder.classList.remove(searchResultsHolderIsSearchingClass)}, 800)

        // it's successful
        if(request.status === 200) {
            const res = JSON.parse(request.responseText);
			console.log(res)
        	   handleSearchResponse(res)
        } else {
          // handleError
          console.log('ERROR: ' +  request.status + ' ' + request.statusText);
        }
      }
  }

  request.open('POST', ajaxurl, true);
  request.send(formData);
}

//handle response
const handleSearchResponse = (res) => {
  renderResponseHtml(res.resHtml)

}

// render html of response
const renderResponseHtml = (res) => {
	if(!searchResultsHolder || typeof searchResultsHolder === 'undefined') {
		return false;
	}

  searchResultsHolder.innerHTML = ''

	if(typeof res === 'object') {
      searchResultsHolder.classList.add(searchResultsHolderIsSearchingClass)
    	searchResultsHolder.appendChild(res)
      setTimeout(() => {searchResultsHolder.classList.remove(searchResultsHolderIsSearchingClass)}, 800)
  	} else if(res === 'undefined' || typeof res === 'undefined'){
		  searchResultsHolder.innerHTML = ''
	} else {
	   searchResultsHolder.innerHTML = res
	}

}

const searchFieldHandler  = () => {

	if( (!searchInputField || typeof searchInputField === 'undefined') || (!searchForm || typeof searchForm === 'undefined')) {
		return false
	}
	postType = searchForm.getAttribute('data-type') || false

  if(!postType || typeof postType === 'undefined') {
    return false
  }

  // loader
  loader = new Loader(searchHolder)

  if(searchForm.addEventListener) { searchForm.addEventListener('submit', (e) => e.preventDefault(), false) }
  else if(searchForm.attachEvent) { searchForm.attachEvent('onsubmit', (e) => e.preventDefault() ) }

  searchResultsHolder = document.getElementById(postType + 'ListingPostsHolder')
  searchResultsHolder.classList.add('animate-search')

  allOriginalPosts = (searchResultsHolder || typeof searchResultsHolder !== 'undefined') ? searchResultsHolder.cloneNode(true) : false

	searchInputField.addEventListener('keyup', ((e) => handleKeypress(e, searchInputField.value)), false)
	searchInputField.addEventListener('touchend', ((e) => handleKeypress(e, searchInputField.value)), false)



}

export default searchFieldHandler
